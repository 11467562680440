import React, { useContext } from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Section, PageHeader, styles, Paragraph, content } from "../utils"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
//import LanguageContext from "../contexts/LanguageContext"

const Bold = ({ children }) => <span>{children}</span>
const Text = ({ children }) => <BlogParagraph>{children}</BlogParagraph>
const Code = ({ children }) => <CodeWrapper>{children}</CodeWrapper>
const Quote = ({ children }) => <QuoteWrapper>{children}</QuoteWrapper>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.CODE]: text => <Code>{text}</Code>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <img src={node.data.target.fields.file["fr-FR"].url} />
    ),
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
  },
}

const BlogTemplate = ({ data }) => {
  //const { lang } = useContext(LanguageContext)
  console.log(data)
  return (
    <Layout>
      <PostContainer>
        <SideBar>
          <Paragraph>{content.BlogPresentation.fr}</Paragraph>
          <small>© 2019 Mathias Béraud</small>
        </SideBar>
        <PostWrapper>
          <PostHeader>
            <h1>{data.contentfulBlogPost.title}</h1>
            <h3>{data.contentfulBlogPost.teaser.teaser}</h3>
            <p>{`Publié le ${data.contentfulBlogPost.fr}`}</p>
          </PostHeader>
          <PostContent>
            {documentToReactComponents(
              data.contentfulBlogPost.content.json,
              options
            )}
          </PostContent>
        </PostWrapper>
      </PostContainer>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      teaser {
        teaser
      }
      content {
        json
      }
      fr: updatedAt(locale: "fr-FR", formatString: "Do MMM YYYY")
    }
  }
`

export default BlogTemplate

const BlogParagraph = styled.p`
  margin: 1rem 0;
  text-align: left;
`

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0 0 1rem;
  @media screen and (max-width: 900px) {
    display: none;
  }
  & > small {
    margin: 3rem 0;
  }
`

const PostContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1050px 1fr;
  grid-column-gap: 45px;
  padding: 0 1.5rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
    grid-column-gap: 0;
  }
`
const PostWrapper = styled.div`
  padding: 0 1rem;
`

const PostHeader = styled.header`
  margin: 0 0 4rem;
  border-bottom: 1px solid ${styles.colors.primaryFont};
  & > h1,
  h3 {
    font-weight: 400;
  }
  & > h1 {
    font-size: 5rem;
    margin: 3rem 0;
  }
  & > h3 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
  & > p {
    color: ${styles.colors.mediumGrey};
    margin-bottom: 2rem;
    font-weight: 700;
    text-align: right;
  }
`

const PostContent = styled.div`
  margin: 0 0 5rem;
  & > p {
    font-size: 2rem;
  }
  & > img {
    width: 100%;
    max-width: 50rem;
  }
`

const CodeWrapper = styled.code`
  padding: 0 0.5rem;
  border-radius: 2px;
  background-color: #eee;
`

const QuoteWrapper = styled.blockquote`
  padding: 2rem;
  border-left: 0.5rem solid #e5e5e5;
  background-color: #f3f3f3;
  margin: 2rem 0;
`
